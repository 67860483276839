@import "../../../resources/styles/scss/variables.scss";
@import "../../../resources/styles/scss/mixins.scss";

.martleyNutshell {
  background: #000000;

  .component {
    padding: 3em 2.5em;
    background: url(../../../resources/images/about/mbd-about.jpg) center center no-repeat;
    background-size: cover;

    .title {
      padding-top: 18em;
      padding-bottom: 1.5em; // Reduced spacing to tighten layout

      @include max($maxMD) {
        padding-top: 12em;
        padding-bottom: 0.75em; // Mobile-friendly tighter spacing
      }

      h2 {
        font-size: 3.875em;
        font-weight: bold;
        color: $white2;
        margin-bottom: 0.3em;
        text-shadow: 0 0 10px rgba(#000, 1);
        line-height: 1.2;

        @include max($maxMD) {
          font-size: 2.2em;
        }

        span {
          display: inline;
          color: $white2;
          font-weight: bold;

          &.highlight {
            color: $red2;
          }
        }
      }

      h4 {
        color: $white2;
        font-size: 1.8em;
        font-weight: 400;
        font-family: $font-roboto;
        max-width: 600px;

        @include max($maxMD) {
          font-size: 1.3em;
          max-width: 100%;
        }
      }
    }
  }
}
