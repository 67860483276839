@import "../../../resources/styles/scss/variables.scss";
@import "../../../resources/styles/scss/mixins.scss";

.martleyNutshell2 {
    background: #fff;

    .component {
        padding-top: 5em;
        padding-bottom: 5em;

        h4 {
            text-align: left;
            font-family: $font-roboto;
            font-weight: 400;
            line-height: 1.6em;
            font-size: 1.8em;

            @include max($maxMD) {
                font-size: 1.5em;
            }
        }
    }
}