@import "resources/styles/scss/variables";
@import "resources/styles/scss/mixins";

.core-services {
    .component {
        // @include max(767px) {
        //     padding-left: 5.5em;
        //     padding-right: 5.5em;
        // }
    }
    .title {
        margin-top: 4.375em;
        .title-generic {
            h2 {
              font-size: 3.5em;
      
              @media (max-width: 600px) {
                font-size: 3.08em; // 2.2em * 1.4
              }
      
              @media (max-width: 400px) {
                font-size: 2.66em; // 1.9em * 1.4
              }
            }
        }
    }
}