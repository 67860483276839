@import '../../../resources/styles/scss/variables.scss';
@import '../../../resources/styles/scss/mixins.scss';

.hero {
  background-color: $red3;
  padding-top: 18.25em;
  
  &__title {
    margin-bottom: 2em;
    h1 {
      width: 100%;
      max-width: 35rem;
      font-weight: 700;
      text-align: center;
      float: none;
      margin: 0 auto;
      color: $white1;
      letter-spacing: -1.8px;

      @include max($maxSM) {
        font-size: 3.1em;
      }
    }
  }

  &__sub {
    display: flex;
    justify-content: center;
    margin-bottom: 3.8em;

    .sub {
      width: 100%;
      max-width: 25em;
      font-size: 1.7em;
      letter-spacing: 0px;
      text-align: center;
      color: $white2;
      line-height: normal;

      @include max($maxSM) {
        font-size: 1.5em;
      }
    }
  }

  &__btn-wrap {
    margin-bottom: 6.2em;
    display: flex;
    justify-content: center;
  
    @include max($maxSM) {
      font-size: 6px !important;
      margin-bottom: 8em;
    }
  
    .btn {
      &:hover {
        color: $red2;
      }
    }
  }
  
}
